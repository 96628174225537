body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin-block-start: 0;
    margin-block-end: 0;
}

/* Treat all headers are semantic elements, not as visual elements. Has to do with WCAG. See https://www.w3.org/WAI/standards-guidelines/wcag/ */
h1, h2, h3, h4, h5, h6 {
    font-size: 1rem;
    font-weight: normal;
}
.fieldSizingContent {
    field-sizing: content;
}
